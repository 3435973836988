
             
.login-form {
    .v-card__actions {
        padding: 8px 16px 16px;
    }
    .v-alert.error {
        width: 100%;
    }
}
